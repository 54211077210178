function Legal() {
  return (
    <ul>
      <li>
        If the city gets actual legal mayor and there are referendums because of
        the difference between the mayor government and what I am working on,
        each resident is required to vote, at least half of referendum, although
        the residents don't have to vote in favor of me versus the legal mayor
      </li>
    </ul>
  );
}

export default Legal;
