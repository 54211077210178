function Walkable() {
  return (
    <div>
      <ul>
        <li>There will be a lot of trees outside for temperature control (to fight the hot temperature)</li>
      </ul>
    </div>
  );
}

export default Walkable;
