function Technology() {
  return (
    <ul>
      <li>
        Everyone’s phone will be an ID in important situation, such as alcohol
        purchase and visit to hospital where you can simply tap to sign in. If
        somebody doesn’t own a smart phone (e.g. lose it temporarily) then they
        will be issued a new card as an alternative.
      </li>
      <li>
        Better navigation system since one building will contain dozens of
        stores and restaurants. The first floor of each building will have a
        detailed map tho
      </li>
      <li>
        Shopping cart gathering system from each apartment (and from each floor)
      </li>
    </ul>
  );
}

export default Technology;
